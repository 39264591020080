import "./App.css";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import { Icon, divIcon, point } from "leaflet";
import { Markers } from "./types";

function App() {
  const [text1_1, setText1_1] = useState("");
  const [text1_2, setText1_2] = useState("");
  const [text2, setText2] = useState("");
  const [text3_1, setText3_1] = useState("");
  const [text3_2, setText3_2] = useState("");
  const [text3_3, setText3_3] = useState("");
  const [text4_1, setText4_1] = useState("");
  const [text4_2, setText4_2] = useState("");

  const [svgV1, setSvgV1] = useState(false);

  const [showMap, setShowMap] = useState(false);

  const [markers, setMarkers] = useState<Markers>();
  const [center, setCenter] = useState<[number, number]>();
  const [useProxy, setUseProxy] = useState(false);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/data`);
        const data = await response.json();
        

        if (data.showMap) {
          setShowMap(true);
          if (data.proxy) {
            setUseProxy(true);
          } else {
            setUseProxy(false);
          }

          if (data.lat && data.lon) {
            setDisable(false);
          } else {
            setDisable(true);
          }
          let popUp = `<p>`;
          if (data.country) {
            popUp = popUp + `</p><p>Country: <b>${data.country}</b></p>`;
          }
          if (data.city) {
            popUp = popUp + `</p><p>City: <b>${data.city}</b></p>`;
          }
          if (!data.proxy) {
            popUp = popUp + `</p><p>IP: <b>${data.ip}</b></p>`;
          }
          popUp = popUp + "</p>";
          setMarkers({
            geocode: [data.lat, data.lon],
            popUp: popUp,
          });
          setCenter([data.lat, data.lon]);
        } else {
          setShowMap(false);
          if (data.showSvgV1) {
            setSvgV1(true);
          } else {
            setSvgV1(false);
          }
          if (data.proxy) {
            setText3_1(data.proxy);
            setText3_2(data.proxy);
            setText3_3(data.proxy + ":" + data.ip);
          } else {
            setText3_1(data.proxy);
            setText3_2(data.ip);
            setText3_3(data.ip ? "ip:" + data.ip : "");
          }
          setText2(data.country);
          const city = data.city;
          const l = data.showSvgV1 ? 10 : 17;
          if (city.length > l) {
            setText1_1(city.slice(0, l));
            setText1_2(city.slice(l));
            setText4_1(city.slice(0, l));
            setText4_2(city.slice(l));
          } else {
            setText1_1(data.city);
            setText1_2("");
            setText4_1("");
            setText4_2("");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDisable(true);
        setText1_1("");
        setText1_2("");
        setText2("");
        setText3_1("");
        setText3_2("");
        setText3_3("");
        setText4_1("");
        setText4_2("");
      }
    };
    const intervalId = setInterval(fetchData, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const handleSvgClick = () => {
    const obj = {
      ip: "127.0.0.1",
      debug: false,
      showMap: showMap,
    };
    fetch("/api/data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    });
    setDisable(true);
    setText1_1("");
    setText1_2("");
    setText2("");
    setText3_1("");
    setText3_2("");
    setText3_3("");
    setText4_1("");
    setText4_2("");
  };

  const customIcon = new Icon({
    // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    iconUrl: "icons/placeholder.png",
    iconSize: [38, 38], // size of the icon
  });

  // custom cluster icon
  const createClusterCustomIcon = function (cluster: {
    getChildCount: () => any;
  }) {
    return divIcon({
      html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
      className: "custom-marker-cluster",
      iconSize: point(33, 33, true),
    });
  };

  // Component to update map center
  function ChangeView({ center }: { center: [number, number] }) {
    const map = useMap();
    useEffect(() => {
      if (center) {
        map.setView(center);
      }
    }, [center, map]);
    return null;
  }

  if (showMap) {
    if (useProxy) {
      return (
        <div>
          <h1>Proxy detected</h1>
          <p>Sorry, but we can't show the map, because using proxy.</p>
        </div>
      );
    }
    if (disable) {
      return (
        <div>
          <h1>Cannot render map</h1>
          <p>
            Sorry, but we can't show the map because there is no data to render
            the map.
          </p>
        </div>
      );
    }
    return (
      <>
        {center && (
          <MapContainer center={center || [0, 0]} zoom={8}>
            <ChangeView center={center} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            ></TileLayer>

            <MarkerClusterGroup
              chunkedLoading
              iconCreateFunction={createClusterCustomIcon}
            >
              {/* Mapping through the markers */}
              {markers && (
                <Marker
                  position={markers.geocode}
                  icon={customIcon}
                  key={uuidv4()}
                >
                  {markers.popUp && (
                    <Popup>
                      <div
                        dangerouslySetInnerHTML={{ __html: markers.popUp }}
                      />
                    </Popup>
                  )}
                </Marker>
              )}
            </MarkerClusterGroup>
          </MapContainer>
        )}
      </>
    );
  }
  if (svgV1) {
    return (
      <div className="container">
        <svg
          id="Layer"
          onClick={handleSvgClick}
          data-name="Layer"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 613.8 819.78"
          width="500"
          height="500"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            fillRule: "evenodd",
            clipRule: "evenodd",
          }}
        >
          <defs>
            <style>
              {`
              .cls-3 {
                fill: url(#radial-gradient);
              }

              .cls-4 {
                fill: #fd1411;
              }

              .cls-5 {
                fill: #fff;
              }

              .cls-text1 {
                font-size: 60px;
                fill: #010101;
                font-family: Arial-BoldMT, Arial;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;

              }

              .cls-text2 {
                font-size: 40px;
                fill: #010101;
                font-family: Arial-BoldMT, Arial;
                font-weight: 700;
                text-transform: capitalize;
              }

              .cls-text3 {
                font-size: 40px;
                fill: #010101;
                font-family: Arial-BoldMT, Arial;
                font-weight: 700;
              }
            `}
            </style>
            <radialGradient
              id="radial-gradient"
              cx="316.41"
              cy="742.28"
              fx="316.41"
              fy="742.28"
              r="198.97"
              gradientTransform="translate(0 543.13) scale(1 .27)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#373737" stopOpacity=".71" />
              <stop offset="1" stopColor="#000" stopOpacity="0" />
            </radialGradient>
          </defs>
          <g id="Layer_Image" data-name="Layer_Image">
            <ellipse
              className="cls-3"
              cx="316.41"
              cy="742.28"
              rx="270.5"
              ry="77.5"
            />
            <ellipse
              className="cls-5"
              cx="309.41"
              cy="306.78"
              rx="281.5"
              ry="289"
            />
            <path
              className="cls-4"
              d="M582.94,448.69c-9.12,16.69-19.67,32.59-31.01,47.87-28.52,38.11-61.18,73.6-94.02,107.88-46.78,47.5-92.86,96.28-140.81,142.56-8.82,7.55-14.39,4.75-22.05-3.29-5.42-5.66-12.16-12.65-18-18.81-52.57-55.66-105.08-108.6-157.68-163.63-43.77-44.6-82.97-90.12-103.14-150.57-19.5-56.69-21.58-128.55-5.39-186.77C57.32,49.02,255.36-45.43,419.78,21.72c105.22,40.96,184.78,145.82,193.16,258.98,3.14,42.36-1.31,115.47-30,168ZM430.58,84.51C292,5.81,107.72,79.16,61.75,231.2c-63.77,194.24,114.03,382.43,311.7,328.88,63.87-16.06,121.7-57.83,154.59-113.45,75.48-123.1,32.85-292.67-97.28-362.02l-.19-.1Z"
            />
          </g>
          <g id="text1" data-name="text1">
            <text
              className="cls-text1"
              transform="translate(305 320)"
              textAnchor="middle"
            >
              <tspan x="0" y="0">
                {text1_1}
              </tspan>
              <tspan x="0" y="55">
                {text1_2}
              </tspan>
            </text>
          </g>

          <g id="text2" data-name="text2">
            <text
              className="cls-text2"
              transform="translate(305 170)"
              textAnchor="middle"
            >
              <tspan x="0" y="0">
                {text2}
              </tspan>
            </text>
          </g>

          <g id="text3" data-name="text3">
            <text
              className="cls-text3"
              transform="translate(305 470)"
              textAnchor="middle"
            >
              <tspan x="0" y="0">
                {text3_1}
              </tspan>
              <tspan x="0" y="50">
                {text3_2}
              </tspan>
            </text>
          </g>
        </svg>
      </div>
    );
  }
  return (
    <div className="container">
      <svg
        id="Layer"
        onClick={handleSvgClick}
        data-name="Layer"
        xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 2000 1000"
          width="2000"
          height="1000"
          xmlns="http://www.w3.org/2000/svg"
        style={{
          shapeRendering: "geometricPrecision",
          textRendering: "geometricPrecision",
          fillRule: "evenodd",
          clipRule: "evenodd",
        }}
      >
        <defs>
          <style>
            {`
              @font-face {
                font-family: 'Zamenhof-Plain';
                src: url('/zamenhof_plain.otf') format('opentype');
                font-weight: normal;
                font-style: normal;
              }
              .cls-1 {
                fill: #fff;
              }
              .cls-text1 {
                font-size: 105px;
                font-family: Zamenhof-Plain, Zamenhof;
                text-transform: uppercase;
              }
              .cls-text4 {
                font-size: 70px;
                font-family: Zamenhof-Plain, Zamenhof;
                text-transform: uppercase;
              }
              .cls-text2 {
                font-size: 41px;
                font-family: Zamenhof-Plain, Zamenhof;
                text-transform: uppercase;
              }
              .cls-text3 {
                font-size: 41px;
                font-family: Zamenhof-Plain, Zamenhof;
                text-transform: uppercase;
              }
              .centered-group {
                transform: translate(50%, 50%);
                transform-box: fill-box;
                transform-origin: center;
              }
            `}
          </style>
        </defs>
        <g id="Layer_Image" data-name="Layer_Image"className="centered-group">
          <path d="M1050.49,10.77c4.42,4.49,7.99,11.72,8.53,18.04l-.04,219.96c-1.09,17.51-16.65,28.43-33.31,29.6H31.81c-14.46-1.62-28.93-10.94-31.63-26.04L0,28.1C1.91,10.84,17.79,1.14,34.06,0h991.62c8.58.65,18.76,4.61,24.82,10.77ZM1043.78,17.47c-3.47-4.06-11.97-8-17.34-8.49l-995.9.22c-11.3,2.21-18.99,9.43-20.04,21.14l.18,219.74c1.5,11.04,10.53,17.78,21.13,19.3l997.41-.21c8.74-2.28,17.13-7.89,18.54-17.4l.04-224.47c-.26-2.62-2.33-7.85-4.02-9.83Z" />
          <path
            className="cls-1"
            d="M1043.78,17.47c1.69,1.98,3.76,7.2,4.02,9.83l-.04,224.47c-1.41,9.51-9.8,15.12-18.54,17.4l-997.41.21c-10.6-1.52-19.64-8.26-21.13-19.3l-.18-219.74c1.05-11.71,8.74-18.93,20.04-21.14l995.9-.22c5.37.5,13.87,4.43,17.34,8.49Z"
          />
        </g>

        {text4_2 ? (
          <g id="text4" data-name="text4">
            <text
              className="cls-text4"
              transform="translate(1053 270)"
              textAnchor="middle"
            >
              <tspan x="0" y="0">
                {text4_1}
              </tspan>
              <tspan x="0" y="60">
                {text4_2}
              </tspan>
            </text>
          </g>
        ) : (
          <g id="text1" data-name="text1">
            <text
              className="cls-text1"
              transform="translate(1053 310)"
              textAnchor="middle"
            >
              <tspan x="0" y="0">
                {text1_1}
              </tspan>
            </text>
          </g>
        )} 
        
        <g id="text2" data-name="text2">
          <text
            className="cls-text2"
            transform="translate(1053 390)"
            textAnchor="middle"
          >
            <tspan x="0" y="0">
              {!text3_1 && text3_3}
            </tspan>
          </text>
        </g>
        <g id="text3" data-name="text3">
          <text
            className="cls-text3"
            transform="translate(1053 190)"
            textAnchor="middle"
          >
            <tspan x="0" y="0">
              {text2}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
}

export default App;
